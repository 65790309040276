import {
  rfGoFactor,
  rfPainRelief,
  rfZFactor,
  rfZenFactor,
} from '../../../../src/images';
import { productsKeyConstants } from '../../../../config';

export const showcaseProducts = {
  REL: {
    title: 'Relief Factor',
    segment: 'Pain Relief',
    description: 'You Deserve a Pain-Free Life',
    image: rfPainRelief,
    packet: true,
    url: '/products/relief-factor',
    reviewUrl: '/products/relief-factor#review',
    price: '$19.95',
    rating: productsKeyConstants.RF_RELIEF_FACTOR_QS,
  },
  CLM: {
    title: 'Zen Factor',
    segment: 'Anxiety Relief',
    description: 'Find Your Zen. Change Your Life.',
    image: rfZenFactor,
    packet: false,
    url: '/products/zen-factor',
    price: '$21.95',
  },

  ENR: {
    title: 'Go Factor',
    segment: 'Energy Boost',
    description: 'Smooth, Convenient Energy.',
    image: rfGoFactor,
    packet: false,
    url: '/products/go-factor',
    price: '$29.95',
  },
  SLP: {
    title: 'Z Factor',
    segment: 'Sleep Support',
    description: "A Good Night's Sleep Makes a Great Day.",
    image: rfZFactor,
    packet: false,
    url: '/products/z-factor',
    reviewUrl: '/products/z-factor#review',
    price: '$19.95',
    rating: productsKeyConstants.RF_Z_FACTOR,
  },
};
