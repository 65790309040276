import { Grid, Typography } from '@mui/material';
import styles from './ProductShowcase.module.scss';
import ProductCard from '../../../../commons/components/ui/Cards/ProductCard/ProductCard';
import { showcaseProducts } from './constants';
import { useRef, useState } from 'react';

const ProductShowcase = ({ page }) => {
  //!dont delete
  // const [inView, setNextInView] = useState(1);
  // const sideScroll = (element, direction, speed, distance, step) => {
  //   let scrollAmount = 0;
  //   let slideTimer = setInterval(function () {
  //     if (direction == 'left') {
  //       element.scrollLeft -= step;
  //     } else {
  //       element.scrollLeft += step;
  //     }
  //     scrollAmount += step;
  //     if (scrollAmount >= distance) {
  //       window.clearInterval(slideTimer);
  //     }
  //   }, speed);
  // };
  // const elementIDs = {
  //   1: 'relief-factor',
  //   2: 'zen-factor',
  //   3: 'go-factor',
  //   4: 'z-factor',
  // };
  // const scrollLeft = () => {
  //   // sideScroll(showcaseRef.current, 'left', 15, 270, 10);
  //   var element = document.getElementById(elementIDs[inView - 1]);
  //   element?.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //     inline: 'start',
  //   });
  //   setNextInView((old) => (old == 1 ? 1 : old - 1));
  // };
  // const scrollRight = () => {
  //   // sideScroll(showcaseRef.current, 'right', 15, 270, 10);
  //   var element = document.getElementById(elementIDs[inView + 1]);
  //   element?.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //     inline: 'start',
  //   });
  //   setNextInView((old) => (old == 4 ? 4 : old + 1));
  // };
  //
  // const showcaseRef = useRef(null);

  return (
    <div className={styles.productShowcaseExtender} id="productShowCase">
      <div className={styles.productShowcaseContainer}>
        <Grid container>
          {page !== 'Error' ? (
            <Grid container className={styles.productsPitchContainer}>
              <Grid item xl={6} lg={12} md={8} sx={{ pb: 1 }}>
                <div className={styles.headingWrap}>
                  <Typography
                    variant="h1"
                    component="span"
                    color="primary.main"
                  >
                    The Power to Transform...{' '}
                  </Typography>
                  <div className={styles.headingContinued}>
                    <Typography
                      variant="h1"
                      component="span"
                      color="#2F6B01"
                      className={styles.noWrap}
                    >
                      Energy
                    </Typography>
                    <Typography
                      variant="h1"
                      component="span"
                      color="#532382"
                      className={styles.noWrap}
                    >
                      Anxiety
                    </Typography>
                    <Typography
                      variant="h1"
                      component="span"
                      color="#13586E"
                      className={styles.noWrap}
                    >
                      Pain
                    </Typography>
                    <Typography
                      variant="h1"
                      component="span"
                      color="#014572"
                      className={styles.noWrap}
                    >
                      Sleep
                    </Typography>
                    <Typography
                      variant="h1"
                      component="span"
                      className={styles.spacer}
                    >
                      &nbsp;
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xl={6}></Grid>
              <Grid item xl={4} sx={{ pb: 2 }}></Grid>
            </Grid>
          ) : (
            ''
          )}
          <Grid
            container
            wrap="nowrap"
            className={styles.productsContainer}
            // ref={showcaseRef}
          >
            <Grid item xl={3} sx={{ scrollSnapAlign: 'start' }}>
              <ProductCard
                {...showcaseProducts.REL}
                packet={true}
                className={styles.leftPadding}
              />
            </Grid>

            <Grid item xl={3} sx={{ scrollSnapAlign: 'center' }}>
              <ProductCard {...showcaseProducts.CLM} />
            </Grid>
            <Grid item xl={3} sx={{ scrollSnapAlign: 'center' }}>
              <ProductCard {...showcaseProducts.ENR} />
            </Grid>

            <Grid item xl={3} sx={{ scrollSnapAlign: 'center' }}>
              <ProductCard
                {...showcaseProducts.SLP}
                className={styles.rightPadding}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProductShowcase;
