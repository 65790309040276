import Router from 'next/router';
import styles from './ProductCard.module.scss';
import Image from 'next/image';
import {
  rfPainRelief,
  pillsPacket,
  pillsWithShadow,
  pillWithShadow,
} from '../../../../../src/images';
import { Typography } from '@mui/material';
import { useRef, useState } from 'react';
import {
  ContainedButton,
  LinkArrow,
  RatingWithCount,
  TextWithArrowAnimation,
} from '../../../../hoc';

import imgLoader from '../../../../../imgloader';

const ProductCard = ({
  packet = false,
  title = '',
  image = '',
  segment = '',
  description = '',
  url = '/',
  className = '',
  hidePills = false,
  ...rest
}) => {
  const imgContainerRef = useRef(null);
  const [maxWidth, setmaxWidth] = useState('auto');
  // useEffect(() => {
  //   window.addEventListener('resize', handleResize, false);
  // }, []);
  // useEffect(() => {
  //   if (imgContainerRef?.current) {
  //     let nodeStyle = window.getComputedStyle(imgContainerRef.current);
  //     console.log(
  //       parseFloat(imgContainerRef.current.offsetWidth) +
  //         parseFloat(nodeStyle.getPropertyValue('margin-left')) +
  //         'px'
  //     );
  //     setmaxWidth(parseFloat(imgContainerRef.current.offsetWidth) + 'px');
  //   }
  // }, [imgContainerRef]);

  // const handleResize = () => {
  //   if (imgContainerRef?.current) {
  //     let nodeStyle = window.getComputedStyle(imgContainerRef.current);
  //     setmaxWidth(
  //       parseFloat(imgContainerRef.current.offsetWidth) +
  //         parseFloat(nodeStyle.getPropertyValue('margin-left')) +
  //         parseFloat(nodeStyle.getPropertyValue('margin-right')) +
  //         'px'
  //     );
  //   }
  // };
  return (
    <div className={`${styles.productCardContainer} ${className}`} {...rest}>
      <div
        className={`${styles.productImageWithPillContainer} ${
          !hidePills && styles.productImageWithoutPill
        }`}
        ref={imgContainerRef}
        onClick={() => {
          Router.push(url);
        }}
      >
        <div>
          <div className={styles.productImgContainer}>
            <Image
              alt={'Relief Factor Pain Relief'}
              layout="fill"
              src={image}
              loading="eager"
              objectFit="contain"
              loader={imgLoader}
              className={styles.productImg}
            />
          </div>
        </div>
        {hidePills && (
          <div className={styles.pillsImgContainer}>
            <div className={styles.pillsWithShadowImgWrapper}>
              <div className={styles.pillsWithShadowImgContainer}>
                <Image
                  alt={'Relief Factor Pain Relief'}
                  layout="fill"
                  src={pillsWithShadow}
                  loading="eager"
                  loader={imgLoader}
                />
              </div>
            </div>
            {packet ? (
              <div className={styles.pillsPacketImgWraper}>
                <div className={styles.pillsPacketImgContainer}>
                  <Image
                    alt={'Relief Factor Pain Relief'}
                    layout="fill"
                    src={pillsPacket}
                    loading="eager"
                    loader={imgLoader}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.pillWithShadowImgWrapper}>
                <div className={styles.pillWithShadowImgContainer}>
                  <Image
                    alt={'Relief Factor Pain Relief'}
                    layout="fill"
                    src={pillWithShadow}
                    loading="eager"
                    loader={imgLoader}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.descriptionContainer}>
        <div
          style={{ maxWidth, width: maxWidth, cursor: 'pointer' }}
          onClick={() => {
            Router.push(url);
          }}
        >
          <div className={styles.cardSubtitleContainer}>
            <Typography variant="p1" color="action.gray">
              {segment}
            </Typography>
            <div>{/* <RatingWithCount /> */}</div>
          </div>
          <Typography variant="h4" color="primary.main">
            {title}
          </Typography>
          <Typography
            variant="p1"
            color="primary.black"
            className={styles.description}
          >
            {description}
          </Typography>
        </div>

        <div className={styles.buttonContainer}>
          <ContainedButton
            onClick={() => {
              Router.push(
                {
                  pathname: url,
                  hash: 'buy',
                },
                undefined,
                { scroll: false }
              );
            }}
            btnType="dark"
          >
            Buy Now
          </ContainedButton>
          <LinkArrow
            href={url}
            onClick={() => {
              Router.push(url);
            }}
          >
            Learn More
          </LinkArrow>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
